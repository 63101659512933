.sidenav{
    width: 15vw;
    height: 96vh;
}
.containers{
    width: 15vw;
    height: 96vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
    background-color:rgb(0, 0, 0);
}
.name{
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: 20px 25px;
    padding-top:10px;
}

.input1{
    color: white;
    margin: 20px 20px;
}
.input1:hover{
    background-color:rgb(109, 106, 106) ;
    cursor: pointer;
    border-radius: 7px;
    height: 30px;
}
.input2{
    color: white;
    background-color:rgb(109, 106, 106) ;
    margin: 17px 20px;
    border-radius: 7px;
    height: 30px;
    padding: 4px;
}
.bottom{
   
    margin-bottom: 25px;
   
}
.list2{
   
    display: flex;
    justify-content: space-between;
    margin: 5px 20px;
   
}
.list3{
    background-color: aqua;
    width: 85px;
    height: 25px;
    border-radius: 5px;
    text-align: center;
    color: rgb(32, 32, 224);
}

.nameicon{

    display: flex;
    justify-content: space-between;
    background-color:rgb(109, 106, 106) ;
    width: 85px;
    height: 25px;
    border-radius: 5px;
    
}
.amount{
    margin-right: 8px;
    margin-top: 2px;
    color: white;
}

.verticalLine{
    width: 0.15vw;
    height: 96vh;
    background-color: rgb(109, 106, 106);
    position: relative;
    left: 14.85vw;
    top: -96vh;
}
.icon1{
    background-color: blue;
    border-radius: 50px;
    margin: -3px 10px;
}
.icon2{
    margin: -4px 0px -4px 60px;
}
.icon3{
    margin: -2px 10px;
}
.icon9{
    color: white;
}
.icon10{
  color:white;
}
.icon11{
    color:rgb(71, 51, 197) ;
}
.icon12{
    background-color:blue ;
    border-radius: 50px;
    margin-top: 5px;
    margin-left: 10px;
    color: white;
}

.icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color:rgb(109, 106, 106);
    width: 50px;
    /* position: relative;
    top: -40px;
    left: 53px; */
    border-radius: 10px;
}
.list20{
    display: flex;
    justify-content: space-between;
    margin: 6px 120px 6px 30px;
}